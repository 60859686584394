.content-one, .content-two, .content-three, .content-four, .content-five, .content-six, .workshop, .contact-form{
    height: 100vh;
    display: grid;
    place-items: center;
}



.content-one{
    background: #0070C9;
}
.component-one h1{
    font-family:  'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    margin-bottom: 25px;
    margin-right: -5px;
    padding-top: 65px;
}
.component-one p{
    font-family:  'Roboto', Thin, sans-serif;
    font-weight: 300;
    font-size: 30px;
    line-height: 42px;
    color: #FFFFFF;
    margin-bottom: 30px;
    margin-right: -8px;
}
.component-one button{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    padding: 8px 20px;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.05em;
    color: #011627;
    background: #EAD94C;
    border-radius: 10px;
    border: none;
}
.component-one img{
    width: 100%;
    margin: 0 auto;
    display: block;
}



.content-two button:hover{
    color: #FFFFFF;
}



.component-two-three-four .textarea{
    padding-left: 40px;
}
.component-two-three-four .col-lg-7{
    height: auto;
    display: grid;
    place-items: center;
}
.component-two-three-four h2{
    font-family:  'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.05em;
    color: #011627;
    margin-bottom: 25px;
}
.component-two-three-four p{
    font-family:  'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 38px;
    color: #011627cc;
    margin-bottom: 30px;
}
.component-two-three-four button{
    padding: 10px 25px 12px 18px;
    font-family:  'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.05em;
    color: #ffffff;
    background: #0070C9;
    border-radius: 10px;
    border: none;
}
.component-two-three-four img{
    width: 100%;
    margin: 0 auto;
}



.content-three{
    background: #0070C9;
}
.content-three h2 {
    color: #FFFFFF;
}
.content-three p {
    color: rgba(255, 255, 255, 0.8);
}
.content-three button{
    background: #EAD94C;
    color: #011627;
    border: none;
}



.content-four button:hover{
    color: #FFFFFF;
}



.content-five{
    background: #e6e6e6;
}
.component-five .project-content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}
.component-five .project-content-sm{
    display: none;
}
.component-five h2{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.05em;
    color: #011627;
    margin-bottom: 40px;
}
.component-five .quote, .content-six .quote{
    background: #0070c917;
    padding: 22px 26px;
    margin-bottom: 50px;
}
.component-five i, .content-six i{
    font-family: 'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: #2B2D42;
}
.component-five .project-process{
    padding: 0 24px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.component-five .project-process h3{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.05em;
    color: #011627;
}
.component-five .project-process h6{
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    background: #0070C9;
    border-radius: 50%;
    padding: 4px 15px;
    width: 47px;
    height: 47px;
    margin: 5px 16px 0 0;
}
.component-five .project-process p{
    font-family: 'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    color: #2B2D42;
    width: 94%;
}



.component-six .team-member{
    display: flex;
    justify-content: space-between;
}
.component-six h2{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.05em;
    color: #011627;
    margin-bottom: 80px;
}
.component-six img{
    width: 235px;
    height: 235px;
}
.component-six h3{
    font-family: 'Roboto', Medium, sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #000000;
}
.component-six p{
    font-family: 'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.07em;
    color: #0070C9;
}
.content-six .quote{
    margin-top: 40px;
}



.workshop{
    background: #0070C9;
}
.workshop .col-lg-5{
    height: auto;
    display: grid;
    place-items: center;
}
.workshop h4{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 56px;
    letter-spacing: -0.07em;
    color: #FFFFFF;
    opacity: 0.5;
}
.workshop h2{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
    letter-spacing: -0.07em;
    color: #FFFFFF;
}
.workshop h5{
    font-family: 'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: -32px;
}
.workshop img{
    display: block;
    width: 100%;
    margin: 0 auto;
}
.workshop button{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    padding: 8px 18px;
    background: #FFFFFF;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.05em;
    color: #0070C9;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
}
.workshop button:hover {
    color: #0070C9;
}




.contact-form form{
    width: 90%;
}
.contact-form form input, .contact-form form textarea{
    font-family: 'Roboto', Regular, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 26px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 18px 12px;
}
.contact-form img{
    width: 100%;
    height: auto;
    margin: 44px auto 0;
    display: block;
}
.contact-form h5{
    font-family: 'Roboto', Medium, sans-serif;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.07em;
    color: #0070C9;
    margin-bottom: 40px;
}
.contact-form h2{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 68px;
    line-height: 84px;
    letter-spacing: -0.07em;
    color: #2B2D42;
    margin-bottom: 0;
}
.contact-form button{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    background: #0070C9;
    color: #FFFFFF;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.05em;
    margin-top: 5px;
    padding: 8px 22px;
    border: none;
    border-radius: 8px;
}
.contact-form button:hover {
    color: #FFFFFF;
}
.contact-form .submit-btn{
    display: flex;
    justify-content: flex-start;
}
.contact-form .form-info{
    display: flex;
    margin-top: 10px;
}
.contact-form .form-info img{
    width: 16px;
    height: 16px;
    display: block;
    margin: 3px 7px 0 0;
}
.contact-form .form-info p{
    font-family: 'Roboto', Medium, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #0070C9;
}


.contact-form .back-btn{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    background: #FFFFFF;
    color: #01162782;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.05em;
    margin-top: 5px;
    padding: 5px 16px 8px 0;
    border: none;
    border-radius: 8px;
    display: inline-flex;
}
.contact-form .back-btn:hover{
    color: #01162782;
}
.contact-form .back-arrow{
    width: 26px;
    display: block;
    margin: 0 10px 0 0;
}


.login-form {
    max-width: 300px;
}
.login-form-margin {
    margin-bottom: 16px;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}




.ReactModalPortal h1{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.07em;
}

.ReactModalPortal p{
    font-family: 'Roboto', Medium, sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.07em;
}
.ReactModalPortal button{
    font-family: 'Roboto', Bold, sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.05em;
    background: #EAD94C;
    border-radius: 10px;
}



.d-show{
    display: block;
}
.d-hide{
    display: none;
}















/* Mobile Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (max-width: 767px){
    .content-one, .content-two, .content-three, .content-four, .workshop, .contact-form{
        height: 93.5vh;
        text-align: center;
        min-height: 800px;
    }
    .content-five, .content-six {
        height: auto;
        text-align: center;
        min-height: 860px;
    }


    .component-one, .component-two-three-four, .component-five, .component-six, .workshop, .contact-form{
        padding: 0 10px;
    }



    .d-show{
        display: none;
    }
    .d-hide{
        display: block;
    }




    .component-one h1{
        font-size: 32px;
        line-height: 36px;
        padding-top: 0;
    }
    .component-one p{
        font-size: 18px;
        line-height: 22px;
    }
    .component-one-feature{
        justify-content: center;
    }
    .component-one-feature h4{
        font-size: 22px;
        padding: 0 10px;
    }
    .component-one img{
        width: 100%;
        padding-bottom: 15px;
    }
    .component-one button {
        padding: 6px 15px 10px;
        font-size: 22px;
        line-height: 26px;
    }



    .component-two-three-four .textarea {
        padding-left: 0;
    }
    .component-two-three-four img {
        width: 80%;
        margin-bottom: 20px;
    }
    .component-two-three-four h2{
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 16px;
        padding-top: 20px;
    }
    .component-two-three-four p{
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
    }
    .component-two-three-four button{
        font-size: 22px;
        line-height: 26px;
        padding: 8px 18px 11px;
    }
    .component-two-three-four svg{
        width: 90% !important;
        height: 90% !important;
    }



    .component-five, .component-six {
        padding: 80px 30px !important;
    }
    .component-five h2{
        font-size: 36px;
        line-height: 40px;
    }
    .component-five .project-content{
        display: block;
    }

    .component-five i, .content-six i {
        font-family: 'Roboto', Medium, sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }
    .component-five .project-process h3 {
        font-size: 36px;
        line-height: 52px;
    }
    .content-five .project-process p{
        width: 100%;
        font-size: 20px;
        line-height: 24px;
    }
    .content-five .project-process{
        border: none;
        margin: 40px auto;
    }
    .content-five .project-process .d-flex{
        display: block !important;
    }
    .component-five .project-process h6 {
        font-size: 24px;
        padding: 5px 12px;
        width: 40px;
        height: 40px;
        margin: 0 auto 10px;
    }



    .component-six .team-member{
        display: none;
    }
    .content-six .team-member-sm{
        display: flex;
        justify-content: space-evenly;
    }
    .component-six img {
        width: 165px;
        height: 165px;
    }
    .component-six h2{
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .component-six h3 {
        font-size: 24px;
        line-height: 28px;
    }
    .component-six p {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
    }



    .workshop h4 {
        font-size: 22px;
        line-height: 24px;
    }
    .workshop h2 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 18px;
    }
    .workshop h5 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
        margin-right: 0;
    }
    .workshop button {
        font-size: 22px;
        line-height: 26px;
        padding: 6px 15px 10px;
    }
    .workshop img{
        padding-bottom: 40px;
    }
    .workshop svg{
        padding-top: 4px !important;
    }




    .contact-form form{
        width: 100%;
    }
    .component-eight{
        text-align: left ;
    }
    .contact-form h2 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
    }
    .contact-form h5 {
        font-size: 30px;
        line-height: 32px;
    }
    .contact-form form input, .contact-form form textarea{
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 22px;
        padding: 20px 12px;
    }
    .contact-form .form-info p{
        font-size: 12px;
        line-height: 15px;
    }
    .contact-form button {
        font-size: 22px;
        line-height: 26px;
        margin-top: 5px;
        padding: 10px 22px;
    }
    .contact-form .back-btn{
        margin-top: 56px;
        margin-bottom: 12px;
    }



    .ReactModalPortal p {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.03em;
        padding-bottom: 10px;
    }
    .ReactModalPortal h1 {
        font-size: 40px;
        line-height: 42px;
        letter-spacing: -0.03em;
        padding-bottom: 10px;
    }
    .ReactModalPortal button {
        font-size: 28px;
        line-height: 34px;
        padding: 3px 15px 5px;
    }
    .ReactModal__Content{
        width: 81% !important;
        max-width: 400px !important;
        padding-top: 38px !important;
        height: 450px !important;
    }


}

/* Mobile Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */





/* Mobile Small Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (max-width: 424px){

    .component-one h1 {
        font-size: 28px;
        line-height: 32px;
        padding-top: 0;
    }

    .component-two-three-four h2 {
        font-size: 31px;
        line-height: 38px;
    }

    .component-five, .component-six {
        padding: 80px 5px !important;
    }

    .content-five .project-process p {
        font-size: 18px;
        line-height: 22px;
    }


    .ReactModal__Content {
        width: 81% !important;
        padding-top: 24px !important;
    }

    .ReactModalPortal p {
        font-size: 18px;
        line-height: 22px;
    }
    .ReactModalPortal h1 {
        font-size: 36px;
        line-height: 40px;
    }

}

/* Mobile Small Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



/*Tablet Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-width: 768px) and (max-width: 991px) {
    .content-one{
        height: auto;
        text-align: center;
        min-height: 900px;
    }

    .content-two, .content-three, .content-four, .content-five, .content-six, .workshop, .contact-form{
        height: auto;
        text-align: center;
        min-height: 800px;
    }

    .d-show{
        display: none;
    }
    .d-hide{
        display: block;
    }


    .component-one .textarea{
        width: 70%;
        margin: 0 auto;
    }
    .component-one h1{
        font-size: 40px;
        line-height: 44px;
        padding-top: 0;
    }
    .component-one p{
        font-size: 21px;
        line-height: 28px;
    }
    .component-one button {
        padding: 5px 14px;
        font-size: 26px;
        line-height: 36px;
    }
    .component-one-feature h4{
        font-size: 23px;
    }
    .component-one img {
        width: 60%;
        padding-bottom: 15px;
    }



    .component-two-three-four p{
        font-size: 22px;
        line-height: 28px;
        padding-left: 28px;
    }
    .component-two-three-four button{
        font-size: 30px;
        margin-left: 28px;
    }
    .component-two-three-four h2{
        font-size: 36px;
        line-height: 34px;
        padding-top: 10px;
    }
    .component-two-three-four img {
        width: 50%;
    }
    .component-two-three-four .textarea {
        padding-left: 0;
        width: 75%;
        margin: 0 auto;
    }




    .component-five, .component-six, .workshop {
        padding: 80px 0;
    }
    .component-five h2 {
        font-size: 50px;
        line-height: 56px;
    }
    .component-five i, .content-six i {
        font-family: 'Roboto', Medium, sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
    }
    .component-five .quote{
        margin-bottom: 20px;
    }
    .component-five .project-content{
        display: none;
    }
    .content-five .project-process .d-flex{
        display: block !important;
    }
    .component-five .project-process h6 {
        margin: 0 auto 10px;
    }
    .component-five .project-content-sm{
        display: flex;
        justify-content: space-evenly;
        padding: 30px 0;
    }
    .component-five .project-content-sm .design{
        padding-right: 58px;
    }
    .component-five .project-content-sm .design p{
        width: 107%;
    }
    .component-five .project-content-sm .border-none{
        border: none;
    }



    .content-six .team-member{
        display: none;
    }
    .content-six .team-member-sm{
        display: flex;
        justify-content: space-evenly;
    }


    .workshop img {
        width: 80%;
        margin-bottom: 20px;
    }
    .workshop h2, .workshop h4{
        font-size: 38px;
        line-height: 42px;
    }
    .workshop h5{
        font-size: 26px;
        line-height: 30px;
    }
    .workshop button{
        font-size: 30px;
    }



    .contact-form button{
        font-size: 30px;
    }
    .component-eight{
        text-align: left ;
    }
}

/*Tablet Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



/*Tablet & Desktop Small Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-width: 992px) and (max-width: 1199px) {

    .component-one .col-md-7{
        padding-top: 35px;
    }
    .component-one h1{
        font-size: 48px;
        line-height: 54px;
        margin-right: -8px;
        padding-top: 50px;
    }
    .component-one p{
        font-size: 24px;
        line-height: 28px;
    }
    .component-one-feature h4{
        font-size: 28px;
    }



    .component-two-three-four .textarea {
        padding-left: 0;
    }
    .component-two-three-four p{
        font-size: 24px;
        line-height: 30px;
        padding-left: 28px;
    }
    .component-two-three-four button{
        font-size: 30px;
        margin-left: 28px;
    }
    .component-two-three-four h2{
        font-size: 36px;
        line-height: 34px;
        padding-top: 50px;
        padding-left: 28px;
    }




    .content-five{
        height: auto;
        padding: 80px 0;
        min-height: 1250px;
    }
    .component-five .project-content{
        display: none;
    }
    .component-five .project-content-sm{
        display: flex;
        justify-content: space-evenly;
        padding: 40px 0;
    }
    .component-five .project-content-sm .design{
        padding-right: 74px;
    }
    .component-five .project-content-sm .design p{
        width: 100%;
    }
    .component-five .project-content-sm .border-none{
        border: none;
    }

    .component-five .project-process h3 {
        font-size: 40px;
        line-height: 48px;
    }



    .content-six{
        height: auto;
        padding: 80px 0;
        min-height: 1650px;
    }
    .content-six .team-member{
        display: none;
    }
    .content-six .team-member-sm{
        display: flex;
        justify-content: space-evenly;
    }
    .component-five i, .content-six i {
        font-size: 28px;
        line-height: 36px;
    }
    .content-six h2{text-align: center}





    .workshop h4{
        font-size: 28px;
        line-height: 50px;
    }
    .workshop h2{
        font-size: 42px;
        line-height: 48px;
        margin-bottom: 10px;
    }
    .workshop h5{
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0;
    }
    .workshop button{
        font-size: 28px;
        line-height: 32px;
    }



    .contact-form{
        min-height: 980px;
    }

}

/*Tablet & Desktop Small Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



/* Desktop Medium Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    .component-one h1 {
        font-size: 56px;
        line-height: 60px;
        padding-top: 44px;
    }


    .component-two-three-four p{
        font-size: 30px;
        line-height: 36px;
    }
    .component-two-three-four h2{
        font-size: 44px;
        line-height: 52px;
    }


    .content-five{
        min-height: 1000px;
    }
    .component-five i, .content-six i {
        font-size: 30px;
        line-height: 38px;
    }
    .component-five .project-process h6 {
        padding: 3px 13px;
        width: 45px;
        height: 45px;
    }
    .component-five .project-process h3 {
        font-size: 44px;
        line-height: 60px;
    }
    .component-five .project-process p {
        font-size: 18px;
    }



    .content-six{
        min-height: 1024px;
    }
    .content-six .team-member-sm{
        display: none;
    }
    .component-six h3{
        font-size: 30px;
        line-height: 38px;
    }
    .component-six img {
        width: 225px;
        height: 225px;
    }


    .workshop h4 {
        font-size: 32px;
        line-height: 48px;
    }
    .workshop h2 {
        font-size: 50px;
        line-height: 56px;
        margin-bottom: 12px;
    }
    .workshop h5 {
        font-size: 30px;
        line-height: 34px;
    }
    .contact-form{
        min-height: 992px;
    }
    .contact-form img {
        width: 90%;
    }

}

/* Desktop Medium Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



/* Desktop Large Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-width: 1600px) {
    .container{
        max-width: 1535px !important;
    }


    .nav-blue h2, .nav-white h2 {
        font-size: 50px;
    }
    .nav-blue button , .nav-white button{
        font-size: 36px;
    }



    .component-one{
        margin-top: 80px;
    }
    .component-one p {
        font-size: 36px;
        line-height: 44px;
    }
    .component-one img{
        width: 93%;
    }


    .component-two-three-four p{
        font-size: 36px;
        line-height: 42px;
    }
    .component-two-three-four img{
        width: 90%;
        display: block;
    }


    .content-six .team-member-sm{
        display: none;
    }

    .contact-form img{
        width: 100%;
    }

}

/* Desktop Large Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */





/* Iphone 5/SE Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (max-height: 568px) {
    .content-one{
        min-height: 568px;
    }
    .component-one img {
        width: 85%;
    }
    .component-one h1 {
        font-size: 24px;
        line-height: 28px;
        padding-top: 0;
    }


    .component-six img {
        width: 140px;
        height: 140px;
    }
    .component-six h3 {
        font-size: 22px;
        line-height: 26px;
    }
}

/* Iphone 5/SE Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */






/* Iphone 6/7/8 Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-height: 569px) and (max-height: 667px) {
    .content-one{
        min-height: 600px;
    }
    .component-one img {
        width: 65%;
        padding-bottom: 15px;
        padding-top: 60px;
    }
    .component-one button {
        margin-bottom: 30px;
    }
}

/* Iphone 6/7/8 Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */







/* Iphone 6/7/8 plus Screen Start >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

@media screen and (min-height: 668px) and (max-height: 736px) {
    .content-one{
        min-height: 688px;
    }

    .component-one img {
        width: 85%;
    }
}

/* Iphone 6/7/8 plus Screen End >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
